import React, { useEffect } from "react";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
// @mui
import { LoadingButton } from "@mui/lab";
import {
  Stack,
  Link,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import * as CompanyEmailValidator from "company-email-validator";
import { sendOtp, validateOtp } from "../PhoneInterviewApi";
import { validateEmail, validatePhoneNumber } from "../utils/utils";
//import { ga_pg_login_event } from "../../../../../utils/gaEvents";
//import { Link as RouterLink, useHistory } from "react-router-dom";
import CustomPhoneInput from "../CustomPhoneInput";
import InterviewOptions from "./InterviewOptions";
import { getPlaygroundCandidateDetails } from "../PhoneInterviewApi";
import CVParsing from "./CVParsing";
import backgroundimage from "../assets/tina_graphic.jpg";
import logored from "../assets/logored.png";
import animatedlogo from "../assets/animatedlogo.png";
import logowhite from "../assets/logowhite.png";
import loginbackground from "../assets/loginbackground.png";
// ----------------------------------------------------------------------

const playgroundSlug = "playground";

const FormSchema = Yup.object().shape({
  otp: Yup.string().required("OTP is required").length(4, "OTP is invalid"),
});

type FormValuesProps = {
  email: string;
  phone: string;
  otp: string;
  name: string;
  designation: string;
};

type OTPLoginType = "phone" | "email";

type OTPLoginProps = {
  isOpen: boolean;
  setIsOpen: (val) => void;
  onValidate?: () => void;
  loginType?: OTPLoginType;
};

export default function LoginForm({
  isOpen,
  setIsOpen,
  loginType = "phone",
  onValidate = null,
}: OTPLoginProps) {
  const [isOtpSent, setIsOtpSent] = React.useState<boolean>(false);
  const [isOtpValid, setIsOtpValid] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [fetchError, setFetchError] = useState("");
  // let history = useHistory();
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    setValue,
    watch,
  } = useForm<FormValuesProps>({
    mode: "onChange",
    //resolver:  yupResolver(FormSchema),
    resolver: undefined,
    defaultValues: {
      email: "",
      phone: "",
      otp: "",
      name: "",
    },
  });
  const [interviewoptionvisible, setInterviewOptionVisible] = useState(false);
  // interviewoptionvisible is set to true
  let email = watch("email");
  let phone = watch("phone");
  let otp = watch("otp");
  let name = watch("name");
  let designation = watch("designation");

  const onSubmit = async (data: FormValuesProps) => {
    setIsLoading(true);
    validateOtp(email, phone, otp, name, designation, true).then((res) => {
      setIsOtpValid(!res.error);

      if (!res.error) {
        if (!onValidate) {
          setInterviewOptionVisible(true);
          setIsOpen(false);
          /*getInterviewTypesForDemoCompany(playgroundSlug).then((interviews) => {
            history.push(
              `/playground/landing/?linkId=${
                interviews.find((x) => x.role_name === "New conversation")
                  ?.active_link
              }`
            );
          });
          setIsLoading(false);*/
          //ga_pg_login_event("email");
        } else {
          onValidate();
        }
      } else {
        setError("otp", { type: "invalid-otp", message: "OTP is invalid" });
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    setIsOtpSent(false);
  }, [isOpen]);
  useEffect(() => {
    getPlaygroundCandidateDetails()
      .then((res) => {
        console.log("These are fetched candidate details...", res);
        setValue("name", res?.name && res.name != "Error" ? res.name : "");
        setValue("phone", res?.phone_number ? res.phone_number : "");
        if (res.phone_number != null && res.phone_number != "") {
          setIsOpen(false);
          setInterviewOptionVisible(true);
        }
      })
      .catch((e) => {
        localStorage.removeItem("babblebots_auth_data");
      });
  }, []);
  /*This useEffect is present Only for testing 
  useEffect(() => {
    localStorage.setItem("babblebots_auth_data", "TestToken");
    setIsOpen(false);
    setInterviewOptionVisible(true);
    console.log(localStorage.getItem("babblebots_auth_data"));
  }, []);*/
  return (
    <>
      <Dialog
        open={isOpen}
        sx={{
          "& .MuiPaper-root": {
            width: "98vw",
            maxWidth: "98vw",
            color: "black",
            margin: 0,
            backgroundColor: "whitesmoke",
            height: "95vh",
            display: "flex",
            flexDirection: "row",
          },
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              flexDirection: "column",

              position: "relative",
              minWidth: "300px",
              textAlign: "center",
              maxHeight: "100%",
              overflowY: "auto",
              // backgroundImage: `url(${loginbackground})`,

              // backgroundSize: "320px",
              // backgroundRepeat: "repeat",
              "& ::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${loginbackground})`,

                backgroundSize: "320px",
                backgroundRepeat: "repeat",
                opacity: 0.03,
                zIndex: "-10",
              },
            }}
          >
            <Stack
              sx={{
                padding: {
                  xs: "10px",
                  xsm: "20px 40px",
                  sm: "20px 80px",
                },
                zIndex: 2,
                opacity: 1,
              }}
            >
              <img
                src={logored}
                alt="Logo"
                style={{
                  width: "180px", // Adjust width as needed
                  height: "auto",
                  margin: "auto",
                  paddingTop: "15px",
                }}
              />
              <Typography
                variant="subtitle1"
                sx={{ margin: "20px 0px", fontWeight: "700" }}
              >
                Welcome to Our Playground
              </Typography>
              <form
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Stack
                  spacing={2.5}
                  sx={{
                    padding: {
                      xs: "30px 30px",
                      md: "30px 10px",
                      lg: "30px 30px",
                    },
                    width: "300px",
                    backgroundColor: "rgba(255,255,255,1)",
                  }}
                >
                  {loginType == "email" ? (
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Login using your email"
                          disabled={isOtpSent}
                          error={Boolean(error)}
                          helperText={error?.message}
                        />
                      )}
                    />
                  ) : (
                    <Stack
                      sx={{
                        minWidth: "100%",
                      }}
                      spacing={2}
                      direction="column"
                    >
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Name"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#12a797 !important",
                                },

                                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#12a797",
                                },
                              "& .MuiInputBase-input": {
                                color: "black !important",
                                padding: "10px 15px",
                              },
                              "& .css-csrp5r-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill":
                                {
                                  WebkitBoxShadow: "none",
                                  WebkitTextFillColor: "black",
                                },
                              "& .MuiInputLabel-root": {
                                top: "-6px",
                              },
                              "& .MuiInputLabel-shrink": {
                                transform: "translate(14px, -8px) scale(0.75)",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root":
                                {
                                  color: "#12a797",
                                },
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Email"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#12a797 !important",
                                },
                              },
                              "& .MuiInputBase-input": {
                                color: "black",
                                padding: "10px 15px",
                              },
                              "& .css-csrp5r-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill":
                                {
                                  WebkitBoxShadow: "none",
                                  WebkitTextFillColor: "black",
                                },
                              "& .MuiInputLabel-root": {
                                top: "-6px",
                              },
                              "& .MuiInputLabel-shrink": {
                                transform: "translate(14px, -8px) scale(0.75)",
                              },
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="designation"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Company Designation"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#12a797 !important",
                                },
                              },
                              "& .MuiInputBase-input": {
                                color: "black",
                                padding: "10px 15px",
                              },
                              "& .css-csrp5r-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill":
                                {
                                  WebkitBoxShadow: "none",
                                  WebkitTextFillColor: "black",
                                },
                              "& .MuiInputLabel-root": {
                                top: "-6px",
                              },
                              "& .MuiInputLabel-shrink": {
                                transform: "translate(14px, -8px) scale(0.75)",
                              },
                            }}
                          />
                        )}
                      />
                      <CustomPhoneInput
                        value={phone}
                        onChange={(val) => {
                          setValue("phone", val);
                        }}
                        defaultCountry={"US"}
                        inputProps={{
                          label: "Phone Number",
                          variant: "standard",
                        }}
                      />
                    </Stack>
                  )}
                  {!isOtpSent && (
                    <LoadingButton
                      fullWidth
                      disabled={
                        loginType == "email"
                          ? !email ||
                            !validateEmail(email) ||
                            !CompanyEmailValidator.isCompanyEmail(email)
                          : !phone || !validatePhoneNumber(phone)
                      }
                      sx={{
                        "&.Mui-disabled": {
                          backgroundColor: "rgba(200, 200, 200, 0.6)",
                          color: "rgba(200, 200, 200, 1)",
                        },
                        borderRadius: "8px",
                        width: "300px",
                        padding: "8px",
                      }}
                      variant="outlined"
                      loading={isLoading}
                      onClick={() => {
                        setIsLoading(true);
                        //Changed email field from sendOtp to ""
                        sendOtp("", phone)
                          .then((res) => {})
                          .finally(() => {
                            setIsOtpSent(true);
                            setIsLoading(false);
                          });
                      }}
                    >
                      Get verification code
                    </LoadingButton>
                  )}

                  {isOtpSent && (
                    <>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        You will receive verification code on{" "}
                        {loginType === "email" ? email : phone}
                      </Typography>

                      {/* <OtpInput value={getValues().otp} setValue={(val) => setValue('otp', val)} /> */}
                      <Controller
                        name="otp"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Verification code"
                            error={Boolean(error)}
                            helperText={error?.message}
                            sx={{
                              "& .MuiInputBase-input:hover": {
                                borderColor: "black !important",
                                color: "black",
                              },
                              "& .MuiInputBase-input": {
                                borderColor: "black !important",
                                color: "black",
                              },
                            }}
                          />
                        )}
                      />
                    </>
                  )}
                  {phone && validatePhoneNumber(phone) && isOtpSent && (
                    <>
                      <DialogActions sx={{ marginTop: "5px" }}>
                        <LoadingButton
                          size="large"
                          variant="outlined"
                          fullWidth
                          loading={isLoading}
                          onClick={() => {
                            setIsLoading(true);
                            sendOtp("", phone).then((res) => {
                              setIsOtpSent(true);
                              setIsLoading(false);
                            });
                          }}
                          sx={{
                            width: "150px",
                            padding: "8px",
                            // border: `1px solid ${theme.palette.info.main}`,
                            // backgroundColor: theme.palette.info.main,
                            // color: theme.palette.info.main,
                            borderRadius: "8px",
                          }}
                        >
                          Resend
                        </LoadingButton>
                        <LoadingButton
                          onClick={handleSubmit(onSubmit)}
                          disabled={!isValid || !isOtpSent || otp?.length != 4}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                          sx={{
                            width: "150px",
                            padding: "8px",
                            // backgroundColor: theme.palette.info.main,
                            // color: "white",
                            borderRadius: "8px",
                            "&.Mui-disabled": {
                              color: "rgb(255, 255, 255)",
                              backgroundColor: "rgb(180, 180, 180)",
                            },
                          }}
                        >
                          Login
                        </LoadingButton>
                      </DialogActions>
                      <Link
                        onClick={() => {
                          setIsOtpSent(false);
                          setValue("otp", "");
                        }}
                        sx={{
                          marginTop: "-10px",
                          color: "black",
                          fontSize: "14px",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                      >
                        Change{" "}
                        {loginType === "email"
                          ? "email address"
                          : "phone number?"}
                      </Link>
                    </>
                  )}
                </Stack>
              </form>
            </Stack>
          </Grid>
          <Grid
            item
            md={7}
            sx={{
              height: "100%",
              backgroundImage: `url(${animatedlogo}) `,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat ",
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          >
            {/* <img
            src={logowhite}
            alt="Logo"
            style={{
              width: "240px", // Adjust width as needed
              padding: "24px 80px 0px",
            }}
          /> */}
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Stack
                sx={{
                  color: "white",
                  padding: "0px 80px",
                  width: {
                    xs: "80%",
                    md: "70%",
                    lg: "55%",
                  },
                  marginTop: "-132px",
                }}
              >
                <Typography variant="h3" sx={{ marginBottom: "20px" }}>
                  Reimagine Hiring with Babblebots’ AI-Recruiter!
                </Typography>{" "}
                <br />
                <Typography variant="subtitle1">
                  Engage in a 2-min interview with our AI-Recruiter. Discover
                  how humanlike VoiceAI can drive efficiency in hiring and
                  create delightful candidate interactions.
                  <br />
                  Once logged in, we will guide you through to initiate the
                  call.
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Dialog>
      {interviewoptionvisible && (
        <InterviewOptions username={name} phone={phone} />
      )}
      {/* {interviewoptionvisible && <CVParsing />} */}
    </>
  );
}
