import { get, patch, post, put, httpDelete } from "./ApiHelpers";

export function sendOtp(email: string, phone: string) {
  return post(`/send_otp/`, { email, phone });
}
export function validateOtp(
  email: string,
  phone: string,
  otp: string,
  first_name: string,
  designation: string,
  playground_flag: boolean
) {
  return post(`/validate_otp/`, {
    email,
    phone,
    otp,
    first_name,
    designation,
    playground_flag,
  }).then((res) => {
    console.log("validate_otp", res);
    if (!res.error) {
      localStorage.setItem("babblebots_auth_data", JSON.stringify(res));
    }
    return res;
  });
}
export const getInterviewTypesForDemoPhoneInterviews = (): Promise<any> => {
  return get(`/company/playground/get_playground_interview_type_list/`);
};

export const getPlaygroundCandidateDetails = () => {
  return get(`/company/playground/get_playground_candidate_details/`);
};

export const InvitePhoneCandidatePlayground = (
  phoneinterviewId: number,
  data
) => {
  return post(
    `/company/playground/playground_phone_candidate_invite/${phoneinterviewId}/`,
    data
  );
};

export const currentInterviewStatusPlayground = (candidateId: number) => {
  return get(
    `/company/playground/get_playground_phone_interview_status/${candidateId}/`
  );
};
export function getAuthToken() {
  let currentUser = getCurrentUserDetails();
  console.log("this is current user", currentUser);
  const hasValidToken =
    currentUser?.token &&
    new Date(currentUser.expiry).getTime() >
      new Date(new Date().toUTCString()).getTime();
  let parsedAuthData;
  try {
    parsedAuthData = hasValidToken
      ? JSON.parse(localStorage.getItem("babblebots_auth_data") || "{}")?.token
      : {};
  } catch (e) {
    console.error("Error parsing babblebots_auth_data from localStorage:", e);
    parsedAuthData = {};
  }
  return hasValidToken ? parsedAuthData : null;
  /*const authDetails = localStorage.getItem("babblebots_auth_data");
  if (authDetails) {
    return JSON.parse(authDetails).token;
  }
  return "";*/
}
export function getCurrentUserDetails() {
  const authData = localStorage.getItem("babblebots_auth_data");

  // If it's JSON, parse it, otherwise return it directly
  try {
    return authData ? JSON.parse(authData) : null; // In case it's valid JSON
  } catch (e) {
    console.warn(
      "Stored auth data is not a valid JSON, returning as string",
      e
    );
    return authData; // Fallback to return raw string (likely a token)
  }
}
