import React, { useEffect, useState } from "react";
import { currentInterviewStatusPlayground } from "../PhoneInterviewApi";
import {
  Button,
  Stack,
  Typography,
  TextField,
  Link,
  Dialog,
  useTheme,
  Card,
  CardContent,
  Radio,
  Chip,
  DialogContent,
  Paper,
  Tooltip,
  Alert,
  AlertTitle,
  Box,
  Popover,
} from "@mui/material";
import "./CandidateReport.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import moment from "moment";
import ReactAudioPlayer from "react-audio-player";
import { CancelOutlined } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RingVolumeIcon from "@mui/icons-material/RingVolume";
import CallIcon from "@mui/icons-material/Call";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import phonesound from "../assets/phonesound.mp3";
function CandidateReport({ fetchStatus, setFetchStatus, candidateId }) {
  const [interviewwaitingStatus, setInterviewWaitingStatus] = useState(true);
  const [interviewTranscript, setInterviewTranscript] = useState([]);
  const [status, setStatus] = useState("P");
  const [waitingTime, setWaitingTime] = useState(0);
  const theme = useTheme();
  const [candidateDetails, setCandidateDetails] = useState<any>();
  const [currentAssessment, setCurrentAssessment] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<any>();
  useEffect(() => {
    /*let mockdata = {
      data: {
        id: 2,
        name: "Twinkle",
        communication_email: "test1@gmail.com",
        campaign: {
          id: "cd8c1d3b-47a8-4e0d-a4d0-207f2560e4e4",
          created_on: "2023-04-27T12:06:16.153352Z",
          user: {
            id: 2381,
            first_name: "Dhanesh",
            last_name: "Panicker",
            email: "dhanesh.panicker@mmc.com",
          },
          total_candidates: 0,
          interview_type: 604,
          info: null,
          is_backup: false,
          name: "Link Shared in - Apr 2023",
        },
        company: {
          id: 1,
          name: "BabbleBots",
          description:
            "<p>At Babblebots, we believe we are at the cusp of having human-like conversations with voicebots. These interactions and the problems solved by their capabilities will explode in this decade.</p>",
          contract_active: true,
          is_coding_test_enabled: false,
          logo: "https://storage.googleapis.com/stagging-storage/company/Dummy_image.png",
          slug: "babblebots",
          is_test_company: true,
          region: "In",
          is_whatsapp_enabled: false,
          concised_description:
            "<p>At Babblebots, we believe we are at the cusp of having human-like conversations with voicebots. These interactions and the problems solved by their capabilities will explode in this decade.</p>",
          chargebee_customer_id: "",
          company_url: null,
          linkedin_handler: "",
          company_size: "",
          is_whitelabeled: false,
        },
        cv: null,
        phone_interview_type: {
          phone_interview_type: 1,
          job_title: "QAA",
          questions: [
            {
              test: "Web dev",
            },
          ],
        },
        phone_number: "+918840736701",
        state: "I",
        is_active: true,
        created_on: "2024-10-09T03:07:20.272434Z",
        first_invitation_on: "2024-10-09T03:07:20.272444Z",
        last_status_updated_on: "2024-10-09T03:07:20.272450Z",
        selected_on: null,
        completed_on: null,
        slots: {},
        interview_score: null,
        language_score: {},
        subjective_assessment_score: null,
        assessment_score: null,
        ai_matching_result: [
          {
            match: false,
            caption: "Location Suitability",
            question:
              "This position is based out of Georgia. Does that work for you?",
            expected_answer: "Yes",
            candidate_answer: null,
          },
          {
            match: false,
            caption: "Transportation Availability",
            question:
              "Do you have a reliable mode of transportation? How do you intend to commute to the location? ",
            expected_answer: "Yes",
            candidate_answer: null,
          },
          {
            match: false,
            caption: "Chainsaw Operation Skill",
            question: "Can you operate a Chainsaw? ",
            expected_answer: "Yes",
            candidate_answer: null,
          },
        ],
        cv_ai_matching_result: null,
        interview_summary: "",
        interview_time: null,
        external_application_id: null,
        report: null,
        transcript: [
          {
            role: "bot",
            time: 1729702830003,
            endTime: 1729702833110,
            message: " Hi. Am I speaking to Mega?",
            duration: 1420,
            secondsFromStart: 0,
          },
          {
            role: "user",
            time: 1729702835860,
            endTime: 1729702836360,
            message: "Hello?",
            duration: 500,
            secondsFromStart: 5.69,
          },
          {
            role: "bot",
            time: 1729702838450,
            source: "",
            endTime: 1729702839900,
            message: "Hi. Am I speaking to Megan?",
            duration: 1450,
            secondsFromStart: 8.28,
          },
          {
            role: "user",
            time: 1729702841470,
            endTime: 1729702841970,
            message: "Yes.",
            duration: 500,
            secondsFromStart: 11.3,
          },
          {
            role: "bot",
            time: 1729702844600,
            source: "",
            endTime: 1729702851380,
            message:
              "Hi. I'm calling on behalf of Babblebots. I have a few questions for you about the carpenter position. Is this a good time to talk?",
            duration: 6209.9990234375,
            secondsFromStart: 14.43,
          },
          {
            role: "user",
            time: 1729702853530,
            endTime: 1729702855360,
            message: "Sure. Uh, go ahead, please.",
            duration: 1830,
            secondsFromStart: 23.36,
          },
          {
            role: "bot",
            time: 1729702855779.999,
            source: "",
            endTime: 1729702866510,
            message:
              "Great. Great. I'm Eric, an AI recruiter from Babblebots. I understand that it may be your first time talking to an AI recruiter, so thanks for taking the time to chat with me. Shall we begin?",
            duration: 9050,
            secondsFromStart: 25.609999,
          },
          {
            role: "user",
            time: 1729702868340,
            endTime: 1729702871150,
            message: "Actually, I'm not interest in this role anymore.",
            duration: 2570,
            secondsFromStart: 38.17,
          },
          {
            role: "bot",
            time: 1729702872650,
            source: "",
            endTime: 1729702875910,
            message:
              "No problem. I'll call again later. Have a great day. Bye.",
            duration: 3180,
            secondsFromStart: 42.48,
          },
        ],

        call_recording_url: null,
      },
      waiting: true,
    };
    setCandidateDetails(mockdata.data);
    console.log("These are candidate details", mockdata.data);*/

    let intervalId;

    if (fetchStatus && interviewwaitingStatus && waitingTime < 60) {
      intervalId = setInterval(() => {
        currentInterviewStatusPlayground(candidateId).then((res) => {
          console.log("This is a result", res);
          /*setStatus(
            res?.phone_interview_state && res?.phone_interview_status != ""
              ? res.phone_interview_status
              : status
          );*/
          setStatus(res?.phone_interview_state);
          setInterviewTranscript(res.data?.transcript);

          if (res.data?.transcript?.length > 0) {
            setTimeout(() => {
              setInterviewWaitingStatus(false);
              setStatus("Done");
              setCandidateDetails(res.data);
            }, 2000);

            clearInterval(intervalId);
          }
        });
        setWaitingTime((prev) => prev + 1);
      }, 1000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [fetchStatus]);
  function callsound() {
    new Audio(phonesound).play();
  }
  /*useEffect(() => {
    if (status == "P") {
      callsound();
    }
  }, [status]);*/

  function getStatusElement(currentstatus) {
    let text;
    let icon;
    let customcolor;

    console.log(currentstatus);

    switch (currentstatus) {
      case "P":
        {
          text = "Initiating the Call...";
          icon = <CallIcon />;
          customcolor = theme.palette.warning.main;
        }
        break;
      case "S":
        {
          text = "Interview in progress...";
          icon = <RingVolumeIcon />;
          customcolor = theme.palette.info.main;
        }
        break;
      case "D":
        {
          text = "Fetching the report...";
          icon = <HourglassEmptyIcon />;
          customcolor = theme.palette.success.main;
        }
        break;
      default: {
        text = "Interview in progress...";
        icon = <CallIcon />;
      }
    }
    return (
      <Typography
        variant="body1"
        color={customcolor}
        align="center"
        style={{
          fontWeight: "500",
          animation: "pulse 1s infinite ease-in-out",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography>{icon}</Typography>
        <Typography>{text}</Typography>
      </Typography>
    );
  }
  return (
    <div>
      <Dialog
        open={fetchStatus}
        onClose={() => setFetchStatus(false)}
        sx={{
          "& .MuiPaper-root.MuiDialog-paper": {
            width: status == "Done" ? "720px" : "fit-content",
            maxWidth: "95vw",
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: "16px",
            // padding: "24px",
          },
        }}
      >
        <DialogContent>
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Stack alignItems="center" justifyContent="center">
              {status !== "Done" && getStatusElement(status)}
            </Stack>

            {(status === "Done" && interviewTranscript.length > 0) ||
            (status === "Done" && candidateDetails) ? (
              <Stack spacing={2} sx={{ width: "100%" }}>
                {/* Candidate Details Paper */}
                <Typography textAlign={"center"} variant="h6">
                  Your Report
                </Typography>
                <Paper
                  sx={{
                    padding: theme.spacing(1.5),
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <Stack spacing={1} alignItems="center">
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      color={theme.palette.text.primary}
                    >
                      {candidateDetails.name}
                    </Typography>
                    {!!candidateDetails.completed_on && (
                      <Stack sx={{ fontSize: "14px" }}>
                        {`Completed on ${moment(
                          new Date(candidateDetails.completed_on)
                        ).format("Do MMM, LT ")}`}
                      </Stack>
                    )}
                    <Stack direction="row" spacing={1}>
                      {/* Email Button */}
                      {!!candidateDetails.communication_email && (
                        <Tooltip
                          title={`${candidateDetails.communication_email}\n (click to copy)`}
                          placement="top-start"
                        >
                          <Button
                            variant="text"
                            disableElevation
                            size="small"
                            sx={{
                              justifyContent: "flex-start",
                              paddingLeft: theme.spacing(0.25),
                            }}
                            startIcon={<MailOutlineIcon />}
                            onClick={() =>
                              navigator.clipboard.writeText(
                                candidateDetails.communication_email
                              )
                            }
                          >
                            Email
                          </Button>
                        </Tooltip>
                      )}
                      {/* Phone Button */}
                      {!!candidateDetails.phone_number && (
                        <Tooltip
                          title={`${candidateDetails.phone_number}\n (click to copy)`}
                          placement="top-start"
                        >
                          <Button
                            variant="text"
                            disableElevation
                            size="small"
                            sx={{ justifyContent: "flex-start" }}
                            startIcon={<WhatsAppIcon />}
                            onClick={() =>
                              navigator.clipboard.writeText(
                                candidateDetails.phone_number
                              )
                            }
                          >
                            Phone
                          </Button>
                        </Tooltip>
                      )}
                    </Stack>
                  </Stack>
                </Paper>

                {/* Assessment Paper */}
                {candidateDetails?.ai_matching_result?.length > 0 && (
                  <Paper
                    sx={{
                      padding: theme.spacing(1.5),
                      backgroundColor: theme.palette.background.paper,
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        variant="h6"
                        color={theme.palette.text.primary}
                      >
                        Interview Assessment
                      </Typography>
                      <Stack
                        direction="row"
                        flexWrap="wrap"
                        sx={{
                          justifyContent: "flex-start",
                          padding: 0,
                        }}
                      >
                        {candidateDetails.ai_matching_result?.map(
                          (assessment, index) => (
                            <Alert
                              key={index}
                              icon={
                                assessment.match ? null : <CancelOutlined />
                              }
                              severity={assessment.match ? "success" : "error"}
                              sx={{
                                flex: 1,
                                m: theme.spacing(0.5),
                                minWidth: "calc(50% - 24px)",
                                maxWidth: "calc(50% - 24px)",
                              }}
                              className="ai-matching-box"
                            >
                              <AlertTitle sx={{ ml: theme.spacing(0.75) }}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <span>{assessment.caption}</span>
                                </Stack>
                              </AlertTitle>
                              <Box>
                                <Button
                                  size="small"
                                  sx={{
                                    padding: "5px 10px",
                                    width: "fit-content",
                                  }}
                                  onClick={(event) => {
                                    setCurrentAssessment(assessment);
                                    setAnchorEl(
                                      anchorEl ? null : event.currentTarget
                                    );
                                  }}
                                >
                                  Show Response
                                </Button>
                              </Box>
                            </Alert>
                          )
                        )}
                        <Popover
                          sx={{
                            zIndex: 9999,
                            "& .MuiPopover-paper": {
                              padding: theme.spacing(1.5),
                              maxWidth: "480px",
                            },
                          }}
                          open={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          onClose={() => setAnchorEl(null)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Stack spacing={1}>
                            <Typography>
                              <b>Expected Answer: </b>{" "}
                              {currentAssessment?.expected_answer}
                            </Typography>
                            <Typography>
                              <b>Candidate Answer: </b>{" "}
                              {currentAssessment?.candidate_answer}
                            </Typography>
                          </Stack>
                        </Popover>
                      </Stack>
                    </Stack>
                  </Paper>
                )}
                {/* Interview Recording and Transcript */}
                <Paper
                  sx={{
                    padding: theme.spacing(1.5),
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="h6" color={theme.palette.text.primary}>
                      Interview Recording and Transcript
                    </Typography>
                    <Stack spacing={1}>
                      <ReactAudioPlayer
                        style={{ width: "100%", marginBlock: theme.spacing(2) }}
                        src={candidateDetails.call_recording_url}
                        controls
                      />
                      {candidateDetails.transcript?.map((interaction) => (
                        <Stack
                          key={interaction.message}
                          direction="column"
                          sx={{
                            padding: "8px",
                            borderRadius: "2px",
                            width: "fit-content",
                            backgroundColor:
                              interaction.role === "bot"
                                ? theme.palette.grey[300]
                                : "#a2c1f3",
                          }}
                        >
                          <Typography
                            variant="overline"
                            sx={{ color: "black" }}
                            lineHeight={1.8}
                            fontWeight="600"
                          >
                            {interaction.role === "bot" ? "Eric" : "Candidate"}
                          </Typography>
                          <Typography variant="body2" color="black">
                            {interaction.message}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
            ) : null}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CandidateReport;
