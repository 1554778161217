import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
// import { theme } from "../theme";
import logoimage from "../assets/logo_image.png";
function Header() {
  const theme = useTheme();
  return (
    <Stack
      alignItems={"center"}
      direction={"row"}
      justifyContent={"center"}
      spacing={1}
      sx={{
        backgroundColor: theme.palette.background.default,
        padding: "12px",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      {/* <img
        src="https://storage.googleapis.com/frontend-assets-1/never-fetch/assets/babblebots_darkmode.png"
        alt=""
      /> */}
      <img
        style={{ marginTop: "20px" }}
        src={logoimage}
        height={"48px"}
        alt=""
      ></img>
      {/* <Typography align="center" variant="h6" sx={{ color: "white" }}>
        Playground
      </Typography> */}
      {/* <h1
        style={{
          textAlign: "center",
          color: "white",
          backgroundColor: "#312f2f",
          padding: "0px",
          margin: "0px",
        }}
      >
        Babblebots Playground
      </h1> */}
    </Stack>
  );
}

export default Header;
