import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { theme as customTheme } from "./theme";
import LoginForm from "./components/LoginForm";
import React, { useEffect, useState } from "react";
import Header from "./components/Header";
function App() {
  const theme = createTheme(customTheme);
  const [isOpen, setIsOpen] = useState(true);
  // Changed isOpen to false only for testing
  return (
    <ThemeProvider theme={theme}>
      <div
        className="App"
        style={{
          backgroundColor: theme.palette.background.default,

          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Header />
        <header className="App-header">
          <LoginForm isOpen={isOpen} setIsOpen={setIsOpen} />
        </header>
      </div>
    </ThemeProvider>
  );
}

export default App;
