import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Stack,
  Typography,
  TextField,
  Link,
  Dialog,
  useTheme,
  Card,
  CardContent,
  Radio,
  Box,
} from "@mui/material";
import {
  InvitePhoneCandidatePlayground,
  getInterviewTypesForDemoPhoneInterviews,
} from "../PhoneInterviewApi";
import { AnimatePresence, motion } from "framer-motion";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CandidateReport from "./CandidateReport";
import Header from "./Header";

function PhoneInterviews({ username, phone }) {
  const [fetchStatus, setFetchStatus] = useState(false);
  const [selectedInterviewId, setSelectedInterviewId] = useState(null);
  const interviewRef = useRef(null);
  const [candidateId, setCandidateId] = useState(0);
  const [interviews, setInterviews] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");
  const [popOverOpen, setPopOverOpen] = useState(false);
  const handlePopoverOpen = (event, description) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(description);
    setPopOverOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent("");
    setPopOverOpen(false);
  };

  useEffect(() => {
    getInterviewTypesForDemoPhoneInterviews().then((res) => {
      setInterviews(res);
    });
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        interviewRef.current &&
        !interviewRef.current.contains(event.target)
      ) {
        setSelectedInterviewId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div>
      <Stack
        // fontSize={theme.typography.h4.fontSize}
        // fontWeight={theme.typography.h4.fontWeight}
        // marginBottom={"50px"}
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        color={"white"}
      >
        <Typography variant="h6">Which role do you want to explore?</Typography>
      </Stack>
      <Stack
        ref={interviewRef}
        spacing={2}
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",

          padding: "20px",
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          flexWrap="wrap"
          width="70%"
        >
          {interviews.map((interview, idx) => (
            <Box
              key={interview.id}
              sx={{
                width: {
                  xs: "100%",
                  md: "calc(50%)",
                  lg: "calc(50%)",
                },
                boxSizing: "border-box",
                padding: "6px",
              }}
              onMouseEnter={() => setHoveredIndex(idx)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              {/*<AnimatePresence>
                {hoveredIndex === idx && (
                  <motion.span
                    layoutId="hoverBackground"
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: { duration: 0.15 },
                    }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 0.15, delay: 0.2 },
                    }}
                  />
                )}
              </AnimatePresence>*/}

              <div
                style={{
                  cursor: "pointer",
                  borderWidth: "0px",
                  borderStyle: "solid",
                  borderColor: "#6d6d6d",
                  backgroundColor:
                    selectedInterviewId === interview.id
                      ? "#6d6d6d"
                      : theme.palette.background.paper,

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "24px",
                  position: "relative",
                  borderRadius: "12px",
                  transform: "none",
                }}
                onClick={() => {
                  if (selectedInterviewId === interview.id) {
                    setSelectedInterviewId(null);
                  } else {
                    setSelectedInterviewId(interview.id);
                  }
                }}
              >
                <CardContent
                  style={{
                    padding: "0",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      // fontSize: theme.typography.h5.fontSize,
                      color: theme.palette.text.primary,
                    }}
                  >
                    {interview.name}
                  </Typography>
                  {/* <Typography
                    variant="body2"
                    style={{
                      marginTop: "5px",
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {interview.description}
                  </Typography>*/}
                  {/*
                  <Typography
                    variant="body2"
                    onMouseEnter={(e) => handlePopoverOpen(e, interview.description)}
                    onMouseLeave={handlePopoverClose}
                    style={{
                      marginTop: '5px',
                      color: theme.palette.text.secondary,
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      cursor: 'pointer',
                    }}
                  >
                    {interview.description}
                  </Typography>
                  */}
                </CardContent>

                {/* <Typography
                  style={{
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                > */}
                {selectedInterviewId === interview.id ? (
                  <CheckCircleOutlinedIcon
                    fontSize="small"
                    style={{
                      color: "white",
                      // border: "none",
                      // color: "rgb(18, 167, 151)",
                    }}
                  />
                ) : (
                  <CircleOutlinedIcon
                    fontSize="small"
                    style={{ color: "white" }}
                  />
                )}
                {/* </Typography> */}
                {/*<Radio
                  checked={selectedInterviewId === interview.id}
                  onChange={() => setSelectedInterviewId(interview.id)}
                  value={interview.id}
                  style={{
                    color: theme.palette.primary.main,
                    marginRight: "10px",
                  }}
                />*/}
              </div>
            </Box>
          ))}
        </Box>
        {/*<Popover
          open={popOver}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ pointerEvents: 'none' }}
          PaperProps={{
            style: { padding: '10px', maxWidth: '300px' },
          }}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>*/}
        <Button
          onClick={() => {
            InvitePhoneCandidatePlayground(selectedInterviewId, {
              name: username,
              phone_number: phone,
            }).then((res) => {
              setCandidateId(res.candidate_id);
              setFetchStatus(true);
            });
          }}
          disabled={!selectedInterviewId}
          variant="contained"
          style={{
            marginTop: "20px",
            /*backgroundColor: interviewIdList.includes(selectedInterviewId)
              ? theme.palette.success.light
              : "#B0BEC5",*/
            // backgroundColor: "rgb(18, 167, 151)",
            // padding: "10px 20px",
            // borderRadius: "32px",
            // transition: "background-color 0.3s ease",
            cursor: selectedInterviewId ? "pointer" : "not-allowed",
          }}
        >
          Start Interview
        </Button>
      </Stack>
      {fetchStatus && (
        <CandidateReport
          fetchStatus={fetchStatus}
          setFetchStatus={setFetchStatus}
          candidateId={candidateId}
        />
      )}
    </div>
  );
}

export default PhoneInterviews;
