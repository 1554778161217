import { getAuthToken, getCurrentUserDetails } from "./PhoneInterviewApi";
import { signal } from "@preact/signals-react";

const errorInSavingConfig = signal("");
const baseUrl = "https://interview.babblebots.ai/";
// const baseUrl = "https://interview-staging.babblebots.ai/";
const hasValidToken = () => {
  let currentUser = getCurrentUserDetails();
  return (
    currentUser?.token &&
    new Date(currentUser.expiry).getTime() >
      new Date(new Date().toUTCString()).getTime()
  );
};

function delayPromise(seconds: number) {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(true), seconds);
  });
}

export const fetchWithRetry = async (
  url: string,
  opts: RequestInit,
  tries: number = 2,
  delay = 0
) => {
  const errs = [];

  for (let i = 0; i < tries; i++) {
    try {
      const response = await fetch(url, opts);
      await delayPromise(delay);
      if (response.ok) {
        return response;
      } else {
      }
    } catch (err) {}
  }
};

export const postWithRetry = async (
  url: string,
  body: any,
  tries: number = 2,
  delay = 0
) => {
  if (!url.startsWith("http")) {
    url = baseUrl + url;
  }
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: hasValidToken() ? "Bearer " + getAuthToken() : "",
    },
    body: JSON.stringify(body),
  };
  return fetchWithRetry(url, requestOptions, tries, delay);
};

export const get = async (
  url: string,
  useBaseUrl: boolean = true,
  token = null
) => {
  if (useBaseUrl && !url.startsWith("http")) {
    url = baseUrl + url;
  }
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
          ? "Bearer " + token
          : hasValidToken()
          ? "Bearer " + getAuthToken()
          : "",
        Accept: "application/json",
      },
    });
    if (response.ok) {
      try {
        return await response.json().catch((res) => {
          throw new Error("GET: error converting response json");
        });
      } catch (e) {
        return e;
      }
    } else {
      throw new Error(`${response.status}`);
    }
  } catch (e) {
    throw e;
  }
};

export const httpDelete = async (url: string) => {
  if (!url.startsWith("http")) {
    url = baseUrl + url;
  }
  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: hasValidToken() ? "Bearer " + getAuthToken() : "",
        Accept: "application/json",
      },
    });
    if (response.ok) {
      try {
        return await response.json().catch((res) => {
          throw new Error("DELETE: error converting response json");
        });
      } catch (e) {
        return e;
      }
    } else {
      throw await response.json();
    }
  } catch (e) {
    throw e;
  }
};

export const deleteWithBody = async (url: string, body: any) => {
  if (!url.startsWith("http")) {
    url = baseUrl + url;
  }
  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: hasValidToken() ? "Bearer " + getAuthToken() : "",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });
    if (response.ok) {
      try {
        return await response.json().catch((res) => {
          throw new Error("DELETE: error converting response json");
        });
      } catch (e) {
        return e;
      }
    } else {
      throw await response.json();
    }
  } catch (e) {
    throw e;
  }
};

export const post = async (
  url: string,
  body: any,
  useBaseUrl: boolean = true
) => {
  /*if (useBaseUrl && !url.startsWith("http")) {
    url = baseUrl + url;
    Authorization: hasValidToken() ? "Bearer " + getAuthToken() : "",
  }*/
  url = baseUrl + url;
  console.log("this is my url", url);
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: hasValidToken() ? "Bearer " + getAuthToken() : "",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });
    if (response.ok) {
      try {
        return await response.json().catch((res) => {
          throw new Error("POST: error converting response json");
        });
      } catch (e) {
        return e;
      }
    } else {
      if (response.status === 409)
        errorInSavingConfig.value = "Cannot add duplicate";
      if (response.status === 400) return "Failed";
      throw new Error(`${response.status}`);
    }
  } catch (e) {
    throw e;
  }
};

export const patch = async (url: string, body: any) => {
  if (!url.startsWith("http")) {
    url = baseUrl + url;
  }
  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: hasValidToken() ? "Bearer " + getAuthToken() : "",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  });
  try {
    return await response.json().catch((res) => res);
  } catch (e) {
    return e;
  }
};

export const put = async (url: string, body: any) => {
  if (!url.startsWith("http")) {
    url = baseUrl + url;
  }
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: hasValidToken() ? "Bearer " + getAuthToken() : "",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  });
  try {
    return await response.json().catch((res) => res);
  } catch (e) {
    return e;
  }
};

export function postFormData<T>(url: string, data: FormData): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    const httpRequest = new XMLHttpRequest();

    httpRequest.onreadystatechange = (res: any) => {
      if (httpRequest.readyState == XMLHttpRequest.DONE) {
        console.log("DONE RESPONSE UPLOADING", httpRequest, res);
        if (httpRequest.status < 400) {
          resolve(JSON.parse(httpRequest.responseText));
        } else {
          reject(res);
        }
      }
    };

    httpRequest.onerror = (res: any) => {
      console.log("Error uploading data", res);
      reject(res);
    };

    httpRequest.open("POST", url);
    httpRequest.send(data);
  });
}
