import React, { useEffect, useState } from "react";
import PhoneInterviews from "./PhoneInterviews";
import { useTheme } from "@mui/material/styles";
import WebInterviews from "./WebInterviews";
import { Button } from "@mui/material";
// import CVParsing from "./CVParsing";
export default function InterviewOptions({ username, phone }) {
  const [visibleInterviewOptions, setVisibleInterviewOptions] = useState([
    true,
    false,
    false,
  ]);
  const [allAvailableOptions, setAllAvailableOptions] = useState(false);
  const theme = useTheme();
  return (
    <div>
      <div>
        {allAvailableOptions && (
          <>
            <Button
              onClick={() => {
                setVisibleInterviewOptions([true, false, false]);
                setAllAvailableOptions(false);
              }}
              style={{
                color: theme.palette.success.main,
                fontSize: theme.typography.h3.fontSize,
              }}
            >
              Phone Interviews
            </Button>
            <Button
              onClick={() => {
                setVisibleInterviewOptions([false, true, false]);
                setAllAvailableOptions(false);
              }}
              style={{
                color: theme.palette.success.light,
                fontSize: theme.typography.h3.fontSize,
              }}
            >
              Web Interviews
            </Button>
          </>
        )}
        {visibleInterviewOptions && visibleInterviewOptions[0] && (
          <PhoneInterviews username={username} phone={phone} />
        )}
        {visibleInterviewOptions && visibleInterviewOptions[1] && (
          <WebInterviews />
        )}
        {/* {visibleInterviewOptions && visibleInterviewOptions[2] && <CVParsing />} */}
      </div>
    </div>
  );
}
